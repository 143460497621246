<template>
  <div
    class="modalBg fixed w-full top-0 left-0 right-0 z-mobileMenu h-full"
    @click.self="$emit('close-filter')"
  >
    <transition name="mobileMenu">
      <div
        class="fixed w-full top-0 left-0 h-full"
        @click.self="$emit('close-filter')"
      >
        <div
          class="w-full md:w-[35vw] h-[85%] md:h-[calc(var(--vh)*100)] bottom-0 bg-white absolute overflow-auto overflow-x-hidden pb-112 rounded-t-[16px] md:rounded-none"
          @scroll.stop
          @touchmove.stop
        >
          <div class="flex flex-col">
            <div class="relative text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 z-[1] left-0">
              <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.filterTagButton }}</h4>
              <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                <img
                  :src="'/icons/icon-close.svg?url'"
                  alt="close"
                  class="w-12"
                  height="12"
                  width="12"
                >
              </button>
            </div>

            <div class="text-xsmall-medium sm:text-small-medium border-b border-grey400 px-24 py-24">
              <div
                v-if="productBrands.length > 0"
                class="block py-12 cursor-pointer group "
                @click="subFilterclick('brand')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.brands }}</div>
                  <div class="flex">
                    <div
                      v-if="selectedFilterNumber(productBrands)"
                      class="bg-black rounded-full h-16 w-16 text-white flex items-center justify-center mr-16 text-tiny"
                    >
                      <span class="mt-2 block">{{ selectedFilterNumber(productBrands) }}</span>
                    </div>
                    <img
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      :src="'/icons/icon-right.svg?url'"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="productStrenght.length > 0"
                class="block py-12 cursor-pointer group "
                @click="subFilterclick('strenght')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.strength }}</div>
                  <div class="flex">
                    <div
                      v-if="selectedFilterNumber(productStrenght)"
                      class="bg-black rounded-full h-16 w-16 text-white flex items-center justify-center mr-16 text-tiny"
                    >
                      <span class="mt-2 block">{{ selectedFilterNumber(productStrenght) }}</span>
                    </div>
                    <img
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      :src="'/icons/icon-right.svg?url'"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="productTypes.length > 0"
                class="block py-12 cursor-pointer group "
                @click="subFilterclick('type')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.type }}</div>
                  <div class="flex">
                    <div
                      v-if="selectedFilterNumber(productTypes)"
                      class="bg-black rounded-full h-16 w-16 text-white flex items-center justify-center mr-16 text-tiny"
                    >
                      <span class="mt-2 block">{{ selectedFilterNumber(productTypes) }}</span>
                    </div>
                    <img
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      :src="'/icons/icon-right.svg?url'"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="productFormats.length > 0"
                class="block py-12 cursor-pointer group "
                @click="subFilterclick('format')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.format }}</div>
                  <div class="flex">
                    <div
                      v-if="selectedFilterNumber(productFormats)"
                      class="bg-black rounded-full h-16 w-16 text-white flex items-center justify-center mr-16 text-tiny"
                    >
                      <span class="mt-2 block">{{ selectedFilterNumber(productFormats) }}</span>
                    </div>
                    <img
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      :src="'/icons/icon-right.svg?url'"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="productFlavors.length > 0"
                class="block py-12 cursor-pointer group "
                @click="subFilterclick('flavor')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.flavor }}</div>
                  <div class="flex">
                    <div
                      v-if="selectedFilterNumber(productFlavors)"
                      class="bg-black rounded-full h-16 w-16 text-white flex items-center justify-center mr-16 text-tiny"
                    >
                      <span class="mt-2 block">{{ selectedFilterNumber(productFlavors) }}</span>
                    </div>
                    <img
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      :src="'/icons/icon-right.svg?url'"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="showRunnerFilter() && (listingProductsType !== 'snus-accessories' || listingProductsType !== 'handelsvaror')"
              class="text-xsmall-medium sm:text-small-medium leading-sm border-b border-grey400 px-24 py-24 flex items-center justify-between"
            >
              {{ translation2.latestProduction }}
              <SlidingCheckbox
                v-if="showRunnerFilter()"
                :checked="showRunners"
                @selected="$emit('set-show-runners', showRunnersValue = !showRunnersValue)"
              />
            </div>
            <div class="text-xsmall-medium sm:text-small-medium leading-sm border-b border-grey400 px-24 py-24 flex items-center justify-between">
              <div
                class="block cursor-pointer group w-full"
                @click="subFilterclick('sort')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.sortBy }}</div>
                  <div class="flex">
                    <span class="mr-16 text-xsmall">{{ sortByActiveName(sortBy) }}</span>
                    <img
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      :src="'/icons/icon-right.svg?url'"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="text-xsmall-medium sm:text-small-medium leading-sm px-24 py-24 flex items-center justify-between">
              <div
                class="block cursor-pointer group w-full group"
                @click="subFilterclick('mode')"
              >
                <div class="flex justify-between items-center">
                  <div>{{ translation2.displayMode }}</div>
                  <div class="transform animate-all group-hover:translate-x-8">
                    <img
                      :src="'/icons/icon-right.svg?url'"
                      class="transform animate-all group-hover:translate-x-8 h-12 sm:h-16"
                      alt="Icon right"
                      height="16"
                      width="16"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="absolute left-0 bottom-0 overflow-hidden w-full h-[85%] md:h-full pointer-events-none md:w-[35vw]">
          <!-- Brand -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'brand'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    alt="Icon left"
                    height="8"
                    width="8"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.brands }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div
                  v-for="(brand, index) in productBrands"
                  :key="index"
                  class="mb-12 w-full flex items-center justify-between"
                >
                  <div class="flex">
                    <Checkbox
                      :checked="brand.isSelected ? brand.isSelected : false"
                      :text="brand.name"
                      @on-check="$emit('on-filter', brand, 'Brands')"
                    />
                  </div>
                  <div class="text-xsmall sm:text-xsmall-regular">
                    {{ productsWithTypeTotal(brand) }}
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- Strenght -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'strenght'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                    alt="Icon left"
                    height="8"
                    width="8"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.strength }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div
                  v-for="(strength, index) in productStrenght"
                  :key="index"
                  class="mb-12 w-full flex items-center justify-between"
                >
                  <div class="flex">
                    <Checkbox
                      :checked="strength.isSelected ? strength.isSelected : false"
                      :text="strength.name"
                      @on-check="$emit('on-filter', strength, 'Strengths')"
                    />
                  </div>
                  <div class="text-xsmall sm:text-xsmall-regular">
                    {{ productsWithTypeTotal(strength) }}
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- Type -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'type'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                    alt="Icon left"
                    height="8"
                    width="8"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.type }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div
                  v-for="(type, index) in productTypes"
                  :key="index"
                  class="mb-12 w-full flex items-center justify-between"
                >
                  <div class="flex">
                    <Checkbox
                      :checked="type.isSelected ? type.isSelected : false"
                      :text="type.name"
                      @on-check="$emit('on-filter', type, 'Types')"
                    />
                  </div>
                  <div class="text-xsmall sm:text-xsmall-regular">
                    {{ productsWithTypeTotal(type) }}
                  </div>
                </div>
              </div>
            </div>
          </transition>
  
          <!-- Format -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'format'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    alt="Icon left"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                    height="8"
                    width="8"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.format }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div
                  v-for="(format, index) in productFormats"
                  :key="index"
                  class="mb-12 w-full flex items-center justify-between"
                >
                  <div class="flex">
                    <Checkbox
                      :checked="format.isSelected ? format.isSelected : false"
                      :text="format.name"
                      @on-check="$emit('on-filter', format, 'Formats')"
                    />
                  </div>
                  <div class="text-xsmall sm:text-xsmall-regular">
                    {{ productsWithTypeTotal(format) }}
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- Flavor -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'flavor'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    alt="Icon left"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                    height="8"
                    width="8"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.flavor }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div
                  v-for="(flavor, index) in productFlavors"
                  :key="index"
                  class="mb-12 w-full flex items-center justify-between"
                >
                  <div class="flex">
                    <Checkbox
                      :checked="flavor.isSelected ? flavor.isSelected : false"
                      :text="flavor.name"
                      @on-check="$emit('on-filter', flavor, 'Flavors')"
                    />
                  </div>
                  <div class="text-xsmall sm:text-xsmall-regular">
                    {{ productsWithTypeTotal(flavor) }}
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <!-- Sort -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'sort'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    alt="icon left"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                    height="8"
                    width="8"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.sortBy }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div
                  class="border border-grey400 hover:border-grey800 relative overflow-hidden w-full h-40 flex rounded-md mb-8 animate-all sm:h-48 sm:mb-16"
                  :class="{
                    '!border-black shadow-border-black':
                      sortBy === '',
                  }"
                >
                  <label
                    class="base-text block w-full px-16 cursor-pointer"
                    :for="'popular'"
                  >
                    <div class="relative pl-32 flex justify-between items-center h-full text-xsmall-medium sm:text-small-medium">
                      <div>
                        <input
                          :id="'popular'"
                          class="variant-input flex items-center justify-center appearance-none w-16 h-16 border border-grey600 absolute left-0 rounded top-1/2 transform -translate-y-1/2"
                          type="radio"
                          :value="'popular'"
                          :checked="sortBy === ''"
                          name="sortBy"
                          @change="$emit('on-sort-by', '')"
                        >
                        <span>
                          {{ translation2.classicProducts }}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
                <div
                  class="border border-grey400 hover:border-grey800 relative overflow-hidden w-full h-40 flex rounded-md mb-8 animate-all sm:h-48 sm:mb-16"
                  :class="{
                    '!border-black shadow-border-black':
                      sortBy === 'news',
                  }"
                >
                  <label
                    class="base-text block w-full px-16 cursor-pointer"
                    :for="'popularity'"
                  >
                    <div class="relative pl-32 flex justify-between items-center h-full text-xsmall-medium sm:text-small-medium">
                      <div>
                        <input
                          :id="'popularity'"
                          class="variant-input flex items-center justify-center appearance-none w-16 h-16 border border-grey600 absolute left-0 rounded top-1/2 transform -translate-y-1/2"
                          type="radio"
                          :value="'popularity'"
                          :checked="sortBy === 'news'"
                          name="sortBy"
                          @change="$emit('on-sort-by', 'news')"
                        >
                        <span>
                          {{ translation2.news }}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
                <div
                  class="border border-grey400 hover:border-grey800 relative overflow-hidden w-full h-40 flex rounded-md mb-8 animate-all sm:h-48 sm:mb-16"
                  :class="{
                    '!border-black shadow-border-black':
                      sortBy === 'price',
                  }"
                >
                  <label
                    class="base-text block w-full px-16 cursor-pointer"
                    :for="'price'"
                  >
                    <div class="relative pl-32 flex justify-between items-center h-full text-xsmall-medium sm:text-small-medium">
                      <div>
                        <input
                          :id="'price'"
                          class="variant-input flex items-center justify-center appearance-none w-16 h-16 border border-grey600 absolute left-0 rounded top-1/2 transform -translate-y-1/2"
                          type="radio"
                          :value="'price'"
                          :checked="sortBy === 'price'"
                          name="sortBy"
                          @change="$emit('on-sort-by', 'price')"
                        >
                        <span>
                          {{ translation2.priceLowest }}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
                <div
                  class="border border-grey400 hover:border-grey800 relative overflow-hidden w-full h-40 flex rounded-md mb-8 animate-all sm:h-48 sm:mb-16"
                  :class="{
                    '!border-black shadow-border-black':
                      sortBy === 'priceDesc',
                  }"
                >
                  <label
                    class="base-text block w-full px-16 cursor-pointer"
                    :for="'priceDesc'"
                  >
                    <div class="relative pl-32 flex justify-between items-center h-full text-xsmall-medium sm:text-small-medium">
                      <div>
                        <input
                          :id="'priceDesc'"
                          class="variant-input flex items-center justify-center appearance-none w-16 h-16 border border-grey600 absolute left-0 rounded top-1/2 transform -translate-y-1/2"
                          type="radio"
                          :value="'priceDesc'"
                          :checked="sortBy === 'priceDesc'"
                          name="sortBy"
                          @change="$emit('on-sort-by', 'priceDesc')"
                        >
                        <span>
                          {{ translation2.priceHighest }}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </transition>

          <!-- Mode -->
          <transition name="mobileMenu">
            <div v-if="currentFilter === 'mode'" class="bg-white absolute left-0 overflow-auto w-full h-full pointer-events-auto md:w-[35vw] z-20 rounded-t-[16px] md:rounded-none">
              <div class="relative text-xsmall-medium sm:text-small-medium text-center py-16 sm:py-24 border-b border-grey200 px-24 sticky bg-white z-10 top-0 left-0">
                <button class="w-22 h-22 bg-gray400 text-xsmall-medium sm:text-small-medium flex items-center absolute left-24 top-1/2 transorm -translate-y-1/2 group" @click="currentFilter = ''">
                  <img
                    :src="'/icons/icon-left.svg?url'"
                    alt="icon left"
                    class="w-8 inline-block transform animate-all group-hover:translate-x-4"
                    height="8"
                    width="8"
                  >
                  <span class="inline-block ml-18 relative top-2">{{ translation.navBack }}</span>
                </button>
                <h4 class="text-xs sm:text-sm leading-sm mb-0 text-center">{{ translation2.displayMode }}</h4>
                <button class="w-22 h-22 bg-gray400 absolute right-24 top-1/2 transorm -translate-y-1/2" @click="$emit('close-filter')">
                  <img
                    :src="'/icons/icon-close.svg?url'"
                    alt="close"
                    class="w-12"
                    height="12"
                    width="12"
                  >
                </button>
              </div>
              <div class="p-24 pb-112">
                <div class="text-xsmall-medium sm:text-small-medium leading-sm mb-24 flex items-center justify-between">
                  {{ translation2.largeCanImageMode }}
                  <SlidingCheckbox :checked="useBigLayoutValue" @selected="$emit('set-use-big-layout')" />
                </div>
                <div class="text-xsmall-medium sm:text-small-medium leading-sm mb-24 flex items-center justify-between">
                  {{ translation2.showExcerptMode }}
                  <SlidingCheckbox :checked="useExcerptValue" @selected="$emit('set-excerpt-layout')" />
                </div>
              </div>
            </div>
          </transition>
        </div>

        <div class="absolute bg-white left-0 overflow-hidden w-full bottom-0 md:w-[35vw] border-grey400 border-t z-20">
          <div class="flex py-24 px-24">
            <button class="btn btn--secondary flex-shrink-0 mr-12" @click="$emit('on-reset-filter')">
              {{ translation2.clear }}
            </button>
            <button class="btn btn--primary w-full" @click="$emit('close-filter')">
              {{ translation2.showAll }} {{ filteredProductsLength }} {{ translation2.products }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { computed, ref } from 'vue';
import type { ITag, IProduct } from '~/api-types';
import SlidingCheckbox from '~/components/form-elements/SlidingCheckbox.vue';
import Checkbox from '~/components/form-elements/Checkbox.vue';
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.menuResources;
const translation2 = globalContentStore.productListingResources;

const currentFilter = ref('');
const showRunnersValue = ref(false);

const props = defineProps<{
  tags: ITag[],
  products: IProduct[],
  sortBy: string,
  useBigLayoutValue: boolean,
  useExcerptValue: boolean,
  filteredProductsLength: number,
  showRunners: boolean,
  listingProductsType?: string,
}>();

const emit = defineEmits<{
  (event: 'close-filter'): void,
  (event: 'on-filter', value: ITag, keyWord: string): void,
  (event: 'add-filter', value: ITag, keyWord: string): void,
  (event: 'on-reset-filter'): void,
  (event: 'on-sort-by'): void,
  (event: 'set-use-big-layout'): void,
  (event: 'set-excerpt-layout'): void,
  (event: 'set-show-runners', value: boolean): void,
}>();

showRunnersValue.value = props.showRunners;

const subFilterclick = (index: string) => {
  currentFilter.value = index;
};

const productBrands = computed(() => {
  const tags = props.tags.filter((tag: ITag) => {
    if (tag?.type === 'Brand') {
      return tag;
    }
  });

  tags.sort(function(a: ITag, b: ITag) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return tags;
});

const productStrenght = computed(() => {
  const tags = props.tags.filter((tag: ITag) => {
    if (tag?.type === 'Strength') {
      return tag;
    }
  });

  return tags;
});

const productTypes = computed(() => {
  const tags = props.tags.filter((tag: ITag) => {
    if (tag?.type === 'Type') {
      return tag;
    }
  });

  return tags;
});

const productFormats = computed(() => {
  const tags = props.tags.filter((tag: ITag) => {
    if (tag?.type === 'Format') {
      return tag;
    }
  });

  return tags;
});

const productFlavors = computed(() => {
  const tags = props.tags.filter((tag: ITag) => {
    if (tag?.type === 'Flavor') {
      return tag;
    }
  });
  tags.sort(function(a: ITag, b: ITag) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return tags;
});

const productsWithTypeTotal = ((type: ITag) => {
  let counter = 0;

  props.products.forEach((product: IProduct) => {
    product.tags.forEach((tag: ITag) => {
      if (tag.name === type.name && tag.type === type.type) {
        counter++;
      }
    });
  });

  return counter;
});

const showRunnerFilter = (() => {
  return props.products.filter((product: IProduct) => {
    return product.isRunner;
  }).length > 0;
});

const sortByActiveName = ((value: string) => {
  switch (value) {
    case 'news':
      return translation2.news;
    case 'price':
      return translation2.priceLowest;
    case 'priceDesc':
      return translation2.priceHighest;
    default:
      return translation2.classicProducts;
  }
});

const selectedFilterNumber = ((tags: ITag[]) => {
  let counter = 0;

  tags.forEach((tag: IProduct) => {
    if (tag.isSelected) {
      counter++;
    }
  });

  return counter > 0 ? counter : false;
});

const route = useRoute();

const queryBrands = ref(route.query.Brands);
const queryStrengths = ref(route.query.Strengths);
const queryFormats = ref(route.query.Formats);
const queryTypes = ref(route.query.Types);
const queryFlavors = ref(route.query.Flavors);

const checkQuery = () => {
  if (queryBrands.value) {
    queryBrands.value.split(',').forEach((item: string) => {
      productBrands.value.forEach((brand: ITag) => {
        if (brand.urlSegment === item) {
          emit('on-filter', brand, 'Brands');
        }
      });
    });
  }

  if (queryStrengths.value) {
    queryStrengths.value.split(',').forEach((item: string) => {
      productStrenght.value.forEach((strenght: ITag) => {
        if (strenght.urlSegment === item) {
          emit('on-filter', strenght, 'Strengths');
        }
      });
    });
  }

  if (queryFormats.value) {
    queryFormats.value.split(',').forEach((item: string) => {
      productFormats.value.forEach((format: ITag) => {
        if (format.urlSegment === item) {
          emit('on-filter', format, 'Formats');
        }
      });
    });
  }

  if (queryTypes.value) {
    queryTypes.value.split(',').forEach((item: string) => {
      productTypes.value.forEach((type: ITag) => {
        if (type.urlSegment === item) {
          emit('on-filter', type, 'Types');
        }
      });
    });
  }

  if (queryFlavors.value) {
    queryFlavors.value.split(',').forEach((item: string) => {
      productFlavors.value.forEach((flavor: ITag) => {
        if (flavor.urlSegment === item) {
          emit('on-filter', flavor, 'Flavors');
        }
      });
    });
  }
};

onMounted(()=> {
  checkQuery();
});

</script>

<style scoped lang="postcss">
.mobileMenu-enter-active {
  transition: all 0.4s ease-out;
}
.mobileMenu-leave-active {
  transition: all 0.4s ease-in;
}

.mobileMenu-enter-from,
.mobileMenu-leave-to {
  transform: translateX(100%);
}

</style>
